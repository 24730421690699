import React from "react"
import { Link } from "gatsby"
import { ButtonDisplay } from "utils"
import ReactMarkdown from "react-markdown"
import creditCard from './assets/creditCard.png'
import styles from "./tarifsContain.module.css"

const Titles = (props) => props.list.map((elt, index) => 
  <h5 key={index}>{elt}</h5>
);

const Content = (props) => props.list.map((elt, index) => 
  <div className={styles.leftInfo} key={index}>
    {elt.map((subElt, subIndex) => <p key={subIndex}>{subElt}</p>)}
  </div>
);

export default (props) => (
  <div className={styles.blocksContain}>
    <div className={styles.tarifsContain}>
      <div className={styles.titles}>
        <h3>{props.titleBloc}</h3>
        <h5>{props.subTitleBloc}</h5>
      </div>
      <div className={styles.tarifsInfos}>
        <div className={styles.left}>
          <div className={styles.leftTitles}>
            <Titles list={props.listTarifs && props.listTarifs.titres} />
          </div>
          <Content list={props.listTarifs && props.listTarifs.content} />
        </div>
        <div className={styles.right}>
          <ReactMarkdown source={props.contentRight} />
        </div>
      </div>

      <div className={styles.contactHeight}>
        <div className={styles.contactBloc}>
          <ReactMarkdown source={props.descriptionText} />
          {props.linkButton && <Link to={props.baseUrl + props.linkButton} className={styles.linkButton + " pinkButton"}>
            <ButtonDisplay text={props.textButton} />
          </Link>}
        </div>
        {props.isAcceptedCards && <div className={styles.creditCard}>
          <img src={creditCard} alt="creditCards" />
        </div>}
      </div>

    </div>
    <div
      className={styles.backgroundBloc}
      style={{  backgroundImage: `url(${props.background && props.background.fluid.src})` }}
    >
      
    </div>
  </div>
)