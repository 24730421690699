import React from "react"
import Tarifs from "templates/tarifs"
import { graphql } from 'gatsby'
import { getFieldLang } from "utils"

export default (props) => {
  const {
    data: {
      allDatoCmsTarif
    },
    pageContext: {
      lang,
      slugs
    }
  } = props;

  const data = allDatoCmsTarif && allDatoCmsTarif.edges[0].node;

  return (
    <div>
      {data &&
        <Tarifs
          data={{
            ...data,
            tarifsTitrePage: data[`tarifsTitrePage${lang}`],
            tarifsSoustitrePage: data[`tarifsSoustitrePage${lang}`],
            conditionsDeLocation: data[`conditionsDeLocation${lang}`],
            phraseContact: data[`phraseContact${lang}`],
            texteBoutonContact: data[`texteBoutonContact${lang}`],
            boutonPageContact: data[`boutonPageContact${lang}`],
          }}
          location={{ pathname: '/' }}
          currentPage={ props.location.pathname }
          lang={lang}
          slugs={slugs}
        />
      }
    </div>
  );
};

export const query = graphql`
  query TarifsQuery($website: String!) {
    allDatoCmsTarif(filter: {website: {eq: $website}}) {
      edges {
        node {
          tarifsTitrePageFr
          tarifsTitrePageEn
          tarifsTitrePageIt
          tarifsTitrePageDe
          tarifsSoustitrePageFr
          tarifsSoustitrePageEn
          tarifsSoustitrePageIt
          tarifsSoustitrePageDe
          listeTarifs {
            titres
            content
          }
          conditionsDeLocationFr
          conditionsDeLocationEn
          conditionsDeLocationIt
          conditionsDeLocationDe
          phraseContactFr
          phraseContactEn
          phraseContactIt
          phraseContactDe
          texteBoutonContactFr
          texteBoutonContactEn
          texteBoutonContactIt
          texteBoutonContactDe
          cartesBancairesAcceptees
          illustrationPageTarifs {
            id
            fluid(maxWidth: 1500, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`